// GithubTable.jsx
import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Select,
  MenuItem,
  Box,
  Pagination
} from "@mui/material";
import { format } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import githubLogo from "../assets/github.png";

const GithubTable = ({
  githubs = [],
  selectedGithub,
  setSelectedGithub,
  deleteRequest,
  setOrigin
}) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const formatTime = date => format(new Date(date), "hh:mm:ss a");
  const formatDate = date => format(new Date(date), "MMMM do, yyyy");
  const shouldDisplayDate = (date, previousDate) => {
    if (!previousDate) {
      return true;
    }
    return new Date(previousDate).toLocaleDateString() !== new Date(date).toLocaleDateString();
  };
  const rowStyles = request => {
    return {
      "&:hover": {
        backgroundColor: "rgba(0, 0, 255, 0.04)",
        cursor: "pointer"
      },
      transition: "ease-in 0.3s",
      backgroundColor:
        selectedGithub && selectedGithub._id === request._id ? "rgba(0, 0, 255, 0.04)" : ""
    };
  };
  const renderRequestRow = (request, previousRequest) => (
    <React.Fragment key={request._id}>
      {shouldDisplayDate(request.createdAt, previousRequest?.createdAt) && (
        <TableRow>
          <TableCell colSpan={4} style={{ textAlign: "left", backgroundColor: "lightgray" }}>
            {formatDate(request.createdAt)}
          </TableCell>
        </TableRow>
      )}
      <TableRow
        sx={rowStyles(request)}
        onClick={() => {
          setSelectedGithub(request);
          setOrigin("github");
        }}>
        <TableCell>
          {request.payload.pull_request
            ? request.payload.pull_request.user.login
            : request.payload.pusher
            ? request.payload.pusher.name
            : request.payload.member
            ? request.payload.member.login
            : "GitHub"}
        </TableCell>

        <TableCell>
          {request.payload.pull_request
            ? "pull request"
            : request.payload.pusher
            ? "push"
            : request.payload.member
            ? "collaborator added"
            : "GitHub"}
        </TableCell>

        <TableCell>{formatTime(request.createdAt)}</TableCell>
        <TableCell>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              deleteRequest(request._id);
            }}>
            <DeleteIcon sx={{ fontSize: "18px", "&:hover": { color: "red" } }} />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );

  const headerStyles = {
    color: "black",
    width: "10%",
    fontFamily: "Helvetica Neue",
    fontWeight: "bold"
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: "white", opacity: "0.8" }}>
            <TableCell sx={headerStyles}>Author</TableCell>
            <TableCell sx={{ ...headerStyles, width: "35%" }}>Action</TableCell>
            <TableCell sx={headerStyles}>Time</TableCell>
            <TableCell sx={{ width: "5%" }}>
              <img src={githubLogo} width="100%" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {githubs && githubs.length > 0 ? (
            [...githubs]
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .slice((page - 1) * rowsPerPage, page * rowsPerPage)
              .map((request, index, self) => renderRequestRow(request, self[index - 1]))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No Github Webhook Payloads Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", justifyContent: "space-between", padding: 2 }}>
        <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
        <Pagination
          count={Math.ceil(githubs.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </TableContainer>
  );
};

export default GithubTable;
