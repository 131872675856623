import ReactJson from "react-json-view";
import { Grid, Stack } from "@mui/material";

const JsonView = ({ selectedRequest, origin }) => {
  console.log(origin);
  const title =
    origin === "github"
      ? "Github Payload"
      : origin === "basecamp"
      ? "Basecamp Payload"
      : "Request Data";
  return (
    <Grid item xs={6} sx={{ ml: "20px" }}>
      {selectedRequest && (
        <Stack direction="column" spacing={2}>
          <ReactJson src={selectedRequest} name={title} collapsed={true} />
        </Stack>
      )}
    </Grid>
  );
};

export default JsonView;
