import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useState } from "react";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";

const truncate = (str, max) => (str.length > max ? str.slice(0, max) + "..." : str);

const AnalyticsTable = ({ analytics = [] }) => {
  const [page, setPage] = useState(0);
  const columnsPerPage = 3;
  const transposedData = [
    {
      title: "Time",
      values: analytics.map(({ createdAt }) => new Date(createdAt).toLocaleString())
    },
    {
      title: "GET Requests/Sec",
      values: analytics.map(({ getRequestsPerSecond }) => getRequestsPerSecond)
    },
    {
      title: "POST Requests/Sec",
      values: analytics.map(({ postRequestsPerSecond }) => postRequestsPerSecond)
    },
    {
      title: "Requests with Body/Sec",
      values: analytics.map(({ requestsWithBodyPerSecond }) => requestsWithBodyPerSecond)
    },
    {
      title: "Requests Per Bin (top 3)",
      values: analytics.map(({ requestsPerBin }) =>
        requestsPerBin
          .slice(0, 3)
          .map(obj => `${obj.binId}(${obj.count})`)
          .join(", ")
      )
    },
    {
      title: "Popular Methods (top 3)",
      values: analytics.map(({ popularMethods }) =>
        popularMethods
          .slice(0, 3)
          .map(obj => `${obj.method}(${obj.count})`)
          .join(", ")
      )
    },
    {
      title: "Requests with Content-Type JSON",
      values: analytics.map(({ requestsWithContentTypeJson }) => requestsWithContentTypeJson)
    },
    {
      title: "Common Paths (top 3)",
      values: analytics.map(({ commonPaths }) =>
        commonPaths
          .slice(0, 3)
          .map(obj => `${truncate(obj.path, 20)}(${obj.count})`)
          .join(", ")
      )
    },
    {
      title: "Requests with Queries",
      values: analytics.map(({ requestWithQueries }) => requestWithQueries)
    },
    {
      title: "Requests with Empty Body",
      values: analytics.map(({ requestsWithEmptyBody }) => requestsWithEmptyBody)
    }
  ];

  const handleNextPage = () => {
    if (page < Math.ceil(transposedData[0].values.length / columnsPerPage) - 1) {
      setPage(oldPage => oldPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(oldPage => oldPage - 1);
    }
  };

  const isNextButtonDisabled =
    page >= Math.ceil(transposedData[0].values.length / columnsPerPage) - 1;
  const isPrevButtonDisabled = page <= 0;
  return (
    <TableContainer>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <IconButton onClick={handlePrevPage} disabled={isPrevButtonDisabled}>
          <NavigateBefore />
        </IconButton>
        <IconButton onClick={handleNextPage} disabled={isNextButtonDisabled}>
          <NavigateNext />
        </IconButton>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>
              Metrics
            </TableCell>
            {transposedData[0].values
              .slice(page * columnsPerPage, (page + 1) * columnsPerPage)
              .map((_, i) => (
                <TableCell
                  align="right"
                  key={i}
                  style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>
                  {`Snapshot ${page * columnsPerPage + i + 1}`}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {transposedData.map(({ title, values }) => (
            <TableRow key={title}>
              <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>
                {title}
              </TableCell>
              {values.slice(page * columnsPerPage, (page + 1) * columnsPerPage).map((value, i) => (
                <TableCell align="right" key={i}>
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AnalyticsTable;
