// BasecampTable.jsx
import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Select,
  MenuItem,
  Box,
  Pagination
} from "@mui/material";
import { format } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import basecampLogo from "../assets/basecamp.png";

const BasecampTable = ({
  basecamps = [],
  selectedBasecamp,
  setSelectedBasecamp,
  deleteRequest,
  setOrigin
}) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const formatTime = date => format(new Date(date), "hh:mm:ss a");
  const formatDate = date => format(new Date(date), "MMMM do, yyyy");
  const shouldDisplayDate = (date, previousDate) => {
    if (!previousDate) {
      return true;
    }
    return new Date(previousDate).toLocaleDateString() !== new Date(date).toLocaleDateString();
  };
  const rowStyles = document => {
    return {
      "&:hover": {
        backgroundColor: "rgba(0, 0, 255, 0.04)",
        cursor: "pointer"
      },
      transition: "ease-in 0.3s",
      backgroundColor:
        selectedBasecamp && selectedBasecamp._id === document._id ? "rgba(0, 0, 255, 0.04)" : ""
    };
  };
  const renderRequestRow = (request, previousRequest) => (
    <React.Fragment key={request._id}>
      {shouldDisplayDate(request.createdAt, previousRequest?.createdAt) && (
        <TableRow>
          <TableCell colSpan={6} style={{ textAlign: "left", backgroundColor: "lightgray" }}>
            {formatDate(request.createdAt)}
          </TableCell>
        </TableRow>
      )}
      <TableRow
        sx={rowStyles(request)}
        onClick={() => {
          setSelectedBasecamp(request);
          setOrigin("basecamp");
        }}>
        <TableCell>{request.payload.recording.bucket.name}</TableCell>
        <TableCell>{request.payload.kind}</TableCell>
        <TableCell>{request.payload.recording.content}</TableCell>
        <TableCell>{formatTime(request.createdAt)}</TableCell>
        <TableCell>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              deleteRequest(request.id);
            }}>
            <DeleteIcon sx={{ fontSize: "18px", "&:hover": { color: "red" } }} />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );

  const headerStyles = {
    color: "black",
    fontFamily: "Helvetica Neue",
    fontWeight: "bold"
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "white", opacity: "0.8" }}>
            <TableCell sx={headerStyles}>
              <div style={{ width: "15%" }}>Bucket</div>
            </TableCell>
            <TableCell sx={headerStyles}>
              <div style={{ width: "30%" }}>Kind</div>
            </TableCell>
            <TableCell sx={headerStyles}>
              <div style={{ width: "30%" }}>Content</div>
            </TableCell>
            <TableCell sx={headerStyles}>
              <div style={{ width: "15%" }}>Time</div>
            </TableCell>
            <TableCell style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={basecampLogo} style={{ maxWidth: "32px", maxHeight: "100%" }} />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {basecamps && basecamps.length > 0 ? (
            [...basecamps]
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .slice((page - 1) * rowsPerPage, page * rowsPerPage)
              .map((request, index, self) => renderRequestRow(request, self[index - 1]))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No Basecamp Webhook Payloads Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", justifyContent: "space-between", padding: 2 }}>
        <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
        <Pagination
          count={Math.ceil(basecamps.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </TableContainer>
  );
};

export default BasecampTable;
