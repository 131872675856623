import { useContext, useState, useEffect } from "react";
import { Button, Grid, Stack, Typography, styled, keyframes, Box } from "@mui/material";
import { BinContext } from "../components/BinContext";
import { useNavigate } from "react-router-dom";
import bigDonut from "../assets/snackbin.png";
import errorDonut from "../assets/donut404.png";

import axios from "axios";

const blink = keyframes`
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
`;

const StyledButton = styled(Button)`
  background-color: #36454f;
  color: white;
  height: 70px;
  width: 200px;
  margin: 20px auto;
  font-weight: bold;
  &:hover {
    background-color: #b7553c;
  }
`;

const StyledTypography = styled(Typography)`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #36454f;
  font-weight: bold;
`;

const StyledBlinkTypography = styled(Typography)`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #36454f;
  animation: ${blink} 1s infinite;
`;

const StyledFailed = styled(Typography)`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: maroon;
  font-weight: bold;
`;

const LandingPage = () => {
  const { setBinId } = useContext(BinContext);
  const navigate = useNavigate();
  const [animationRunning, setAnimationRunning] = useState(false);
  const [displayedMsg, setDisplayedMsg] = useState("Creating your bin...");
  const [isError, setIsError] = useState(false);
  const isDevelopment = process.env.NODE_ENV === "development";
  const baseUrl = isDevelopment ? "http://localhost:3001" : "";

  const handleClick = () => {
    setIsError(false);
    setAnimationRunning(true);
  };

  const createBin = async () => {
    try {
      const response = await axios.post(`${baseUrl}/api/bin/new`);
      setBinId(response.data.endpoint_url);
      setTimeout(() => {
        navigate(`/bin/${response.data.endpoint_url}`);
      }, 2000);
    } catch (error) {
      setDisplayedMsg("Failed to create bin. Try again later.");
      setTimeout(() => {
        setAnimationRunning(false);
        setIsError(true);
      }, 2000);
      console.error(error);
    }
  };

  useEffect(() => {
    if (animationRunning && !isError) {
      createBin();
    }
  }, [animationRunning]);

  return (
    <Stack className="LandingPage" sx={{ mt: "20px" }}>
      <StyledTypography variant="h4" align="center" gutterBottom>
        Welcome to SnackBin! Click below to create a bin.
      </StyledTypography>

      <Grid container spacing={3} sx={{ justifyContent: "center", mt: "5%" }}>
        <img src={isError ? errorDonut : bigDonut} alt="Donut" width="50%" />
      </Grid>
      {!animationRunning ? (
        <StyledButton onClick={handleClick}>Create Bin</StyledButton>
      ) : (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          {!isError && (
            <StyledBlinkTypography variant="h5">Creating your bin...</StyledBlinkTypography>
          )}
        </Box>
      )}
      {isError && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <StyledFailed variant="h5">{displayedMsg}</StyledFailed>
        </Box>
      )}
    </Stack>
  );
};

export default LandingPage;
