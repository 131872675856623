import React, { useState, useEffect } from "react";
import { io } from "socket.io-client";
import { Grid, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router";
import JsonView from "./JsonView";
import RequestTable from "./RequestTable";
import GithubTable from "./GithubTable";
import AnalyticsTable from "./AnalyticsTable";
import BasecampTable from "./BasecampTable";

import githubBanner from "../assets/github-banner.png";
import basecampLogo from "../assets/basecamp.png";

const Bin = () => {
  const { binId } = useParams();
  const [requests, setRequests] = useState([]);
  const [basecamps, setBasecamps] = useState([]);
  const [githubs, setGithubs] = useState([]);
  const [analytics, setAnalytics] = useState([]);

  const [tableType, setTableType] = useState("request");

  const [selectedRequest, setSelectedRequest] = useState(null);
  const [origin, setOrigin] = useState(null);

  const isDevelopment = process.env.NODE_ENV === "development";
  const baseUrl = isDevelopment ? "http://localhost:3001" : "";

  useEffect(() => {
    const socket = io(baseUrl);

    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/bin/${binId}`);

        if (response.data && typeof response.data === "object") {
          setRequests(response.data.requests);
          setGithubs(response.data.githubs);
        }
      } catch (error) {
        setRequests([]);
        setGithubs([]);
      }
    };
    fetchData();

    const fetchBasecamps = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/bin/basecamp/${binId}`);
        console.log("fetchBasecamps", response);
        if (response.data && Array.isArray(response.data)) {
          setBasecamps(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.log("No data yet in the bin");
        setBasecamps([]);
      }
    };
    fetchBasecamps();

    const fetchAnalytics = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/analytics/api/all`);

        if (response.data && Array.isArray(response.data)) {
          setAnalytics(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch analytics:", error);
      }
    };

    fetchAnalytics();

    socket.on("update", data => {
      if (Array.isArray(data)) {
        setBasecamps(data);
      } else if (data && typeof data === "object") {
        setRequests(data.requests);
        setGithubs(data.githubs);
      }
    });

    return () => {
      socket.off("update");
      socket.close();
    };
  }, [binId, baseUrl]);

  const deleteRequest = async id => {
    try {
      await axios.delete(`${baseUrl}/api/bin/${binId}/${id}`);
      setRequests(requests.filter(request => request._id !== id));
    } catch (error) {
      console.error("Error deleting request:", error);
    }
  };

  const deleteGithubRequest = async id => {
    try {
      await axios.delete(`${baseUrl}/api/bin/${binId}/github/${id}`);
      setGithubs(githubs.filter(request => request._id !== id));
    } catch (error) {
      console.error("Error deleting github request:", error);
    }
  };

  const deleteBasecampRequest = async id => {
    try {
      await axios.delete(`${baseUrl}/api/bin/${binId}/basecamp/${id}`);
      setBasecamps(basecamps.filter(request => request._id !== id));
    } catch (error) {
      console.error("Error deleting basecamp request:", error);
    }
  };

  const handleTableTypeChange = (event, newTableType) => {
    setTableType(newTableType);
  };

  return (
    <Stack sx={{ mt: "20px" }}>
      <ToggleButtonGroup
        value={tableType}
        exclusive
        onChange={handleTableTypeChange}
        aria-label="Table Type"
        sx={{ mb: 2 }}>
        <ToggleButton value="github">
          <img src={githubBanner} alt="GitHub" width="32px" />
          GITHUB
        </ToggleButton>
        <ToggleButton value="basecamp">
          <img src={basecampLogo} alt="Basecamp" width="32px" />
          Basecamp
        </ToggleButton>
        <ToggleButton value="request">Requests</ToggleButton>
        <ToggleButton value="analytics">Analytics</ToggleButton>
      </ToggleButtonGroup>
      <Grid container>
        {tableType === "analytics" ? (
          <Grid item xs={8}>
            <AnalyticsTable analytics={analytics} />
          </Grid>
        ) : (
          <>
            <Grid item xs={8} md={6}>
              <Stack spacing={2}>
                {tableType === "github" ? (
                  <GithubTable
                    githubs={githubs}
                    selectedGithub={selectedRequest}
                    setSelectedGithub={setSelectedRequest}
                    deleteRequest={deleteGithubRequest}
                    setOrigin={setOrigin}
                  />
                ) : tableType === "request" ? (
                  <RequestTable
                    requests={requests}
                    selectedRequest={selectedRequest}
                    setSelectedRequest={setSelectedRequest}
                    setOrigin={setOrigin}
                    deleteRequest={deleteRequest}
                  />
                ) : (
                  <BasecampTable
                    basecamps={basecamps}
                    selectedBasecamp={selectedRequest}
                    setSelectedBasecamp={setSelectedRequest}
                    deleteRequest={deleteBasecampRequest}
                    setOrigin={setOrigin}
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <JsonView selectedRequest={selectedRequest} origin={origin} />
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
};

export default Bin;
